import React from 'react'

export const GoogleMap = () => (
  <div
    style={{
      display: 'flex',
      overflow: 'hidden',
      maxWidth: '100%',
      height: '500px',
    }}
  >
    <div
      id="embedmap-display"
      style={{
        height: '100%',
        width: '100%',
        maxWidth: '100%',
      }}
    >
      <iframe
        title="Google map Mugaride location"
        style={{ height: '100%', width: '100%', border: 0 }}
        frameBorder="0"
        src="https://www.google.com/maps/embed/v1/search?q=mugaride&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
      ></iframe>
    </div>
  </div>
)
