import React from 'react'
import PropTypes from 'prop-types'

import l from '../localization/fr.json'

import discover from '../images/discover.jpg'
import { GoogleMap } from './GoogleMap'
import { Gallery } from './Gallery'

const Main = (props) => {
  let close = <div className="close" onClick={props.onCloseArticle}></div>
  return (
    <div
      ref={props.setWrapperRef}
      id="main"
      style={props.timeout ? { display: 'flex' } : { display: 'none' }}
    >
      <article
        id={l.homeBtns[0].id}
        className={`${props.article === l.homeBtns[0].id ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">{l.homeBtns[0].title}</h2>
        <span className="image main">
          <img
            src={discover}
            alt="Fond rectangulaire d'un paysage de montagne avec une route"
          />
        </span>
        <p>{l.guideDescription.text1}</p>
        <p>{l.guideDescription.text2}</p>
        <p>{l.guideDescription.text3}</p>
        {/* <p>{l.guideDescription.text4}</p> */}
        {close}
      </article>

      <article
        id={l.homeBtns[1].id}
        className={`${props.article === l.homeBtns[1].id ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">{l.homeBtns[1].title}</h2>
        <h3>
          <span className="icon fa-map-signs" /> {l.formulaFree.title}
        </h3>
        <p>{l.formulaFree.text2}</p>
        <p>
          {l.formulaFree.text1}{' '}
          <a href={'tel:' + l.telNumberInternational}>{l.telNumber}</a>.
        </p>
        <h3>
          <span className="icon fa-user" /> {l.formulaGuide.title}
        </h3>
        <p>{l.formulaTranslation.guideExplanation}</p>
        {l.formulaGuide.prices.map((price, idx) => (
          <div className="columns" key={idx}>
            <ul className="price">
              <li className="header">{price.time}</li>
              {/* <li>
                {price.bikePrice} {l.formulaTranslation.bike}
              </li>
              <li>
                {price.guidePrice} {l.formulaTranslation.guide}
              </li>
              <li>
                {price.launchPrice} {l.formulaTranslation.launchPrice}
              </li> */}
              <li className="grey">{price.total}</li>
            </ul>
          </div>
        ))}
        <h3>
          <span className="icon fa-map" /> {l.formulaGPS.title}
        </h3>
        <p>{l.formulaTranslation.gpsExplanation}</p>
        {l.formulaGPS.prices.map((price, idx) => (
          <div className="columns" key={idx}>
            <ul className="price">
              <li className="header">{price.time}</li>
              {/* <li>
                {price.bikePrice} {l.formulaTranslation.bike}
              </li> */}
              <li>{price.distance}</li>
              <li>{price.level}</li>
              {/* <li>
                {l.formulaTranslation.startPoint} {price.startPoint}
              </li> */}
              <li className="grey">{price.bikePrice}</li>
            </ul>
          </div>
        ))}
        {close}
      </article>

      <article
        id={l.homeBtns[2].id}
        className={`${props.article === l.homeBtns[2].id ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">{l.homeBtns[2].title}</h2>
        <Gallery />
        {close}
      </article>

      <article
        id={l.homeBtns[3].id}
        className={`${props.article === l.homeBtns[3].id ? 'active' : ''} ${
          props.articleTimeout ? 'timeout' : ''
        } contact`}
        style={{ display: 'none' }}
      >
        <h2 className="major">{l.homeBtns[3].title}</h2>
        <p>{l.contact.text}</p>
        {/* <form method="post" action="#">
          <div className="field half first">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" />
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input type="text" name="email" id="email" />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="4"></textarea>
          </div>
          <ul className="actions">
            <li>
              <input type="submit" value="Send Message" className="special" />
            </li>
            <li>
              <input type="reset" value="Reset" />
            </li>
          </ul>
        </form> */}
        <ul className="icons">
          <li>
            <a href={l.links.facebook} className="icon fa-facebook">
              <span className="label">Facebook</span>
            </a>
          </li>
          <li>
            <a href={l.links.instagram} className="icon fa-instagram">
              <span className="label">Instagram</span>
            </a>
          </li>
          <li>
            <a href={l.links.phone} className="icon fa-phone">
              <span className="label">Téléphone</span>
            </a>
          </li>
          <li>
            <a href={l.links.googleMaps} className="icon fa-map">
              <span className="label">Google maps</span>
            </a>
          </li>
        </ul>
        <GoogleMap />
        {close}
      </article>
    </div>
  )
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
