import PropTypes from 'prop-types'
import React from 'react'
import l from '../localization/fr.json'
import logo from '../images/logo-transparent-centered-min.png'

const Header = (props) => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo-container">
      <img
        className="logo"
        src={logo}
        alt="Roue cranté avec Muga Ride écris à l'intérieur"
      />
    </div>
    <div className="content">
      <div className="inner">
        <h1>{l.homeText.title}</h1>
        <p>{l.homeText.shortDescription}</p>
        <p>
          {l.homeText.text2}{' '}
          <a href={'tel:' + l.telNumberInternational}>{l.telNumber}</a>
          {l.homeText.text3}
        </p>
      </div>
    </div>
    <nav>
      <ul>
        {l.homeBtns.map((btn, idx) => (
          <li key={idx}>
            <button
              className="button-mugaride"
              onClick={() => {
                props.onOpenArticle(btn.id)
              }}
            >
              {btn.title}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
