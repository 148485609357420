import React from 'react'
import ImageGallery from 'react-image-gallery'

import gallery1 from './../images/gallery-1.jpeg'
import gallery2 from './../images/gallery-2.jpeg'
import gallery4 from './../images/gallery-4.jpeg'
import gallery5 from './../images/gallery-5.jpeg'
import gallery6 from './../images/gallery-6.jpeg'
import gallery7 from './../images/gallery-7.jpeg'
import gallery8 from './../images/gallery-8.jpeg'
import gallery9 from './../images/gallery-9.jpeg'
import gallery10 from './../images/gallery-10.jpeg'
import gallery11 from './../images/gallery-11-min.jpeg'
import gallery12 from './../images/gallery-12-min.jpeg'
import gallery13 from './../images/gallery-13-min.jpeg'
import gallery14 from './../images/gallery-14-min.jpeg'
import gallery15 from './../images/gallery-15-min.jpeg'
import gallery16 from './../images/gallery-16-min.jpeg'
import gallery17 from './../images/gallery-17-min.jpeg'
import gallery18 from './../images/gallery-18-min.jpeg'
import gallery19 from './../images/gallery-19.jpeg'
import gallery20 from './../images/gallery-20.jpeg'
import gallery21 from './../images/gallery-21.jpeg'
import gallery22 from './../images/gallery-22.jpeg'
import gallery23 from './../images/gallery-23.jpeg'
import gallery24 from './../images/gallery-24.jpeg'
import gallery25 from './../images/gallery-25.jpeg'
import gallery26 from './../images/gallery-26.jpeg'
// import gallery27 from './../images/gallery-27.jpeg'

const images = [
  {
    original: gallery1,
  },
  {
    original: gallery2,
  },
  {
    original: gallery4,
  },
  {
    original: gallery5,
  },
  {
    original: gallery6,
  },
  {
    original: gallery7,
  },
  {
    original: gallery8,
  },
  {
    original: gallery9,
  },
  {
    original: gallery10,
  },
  {
    original: gallery11,
  },
  {
    original: gallery12,
  },
  {
    original: gallery13,
  },
  {
    original: gallery14,
  },
  {
    original: gallery15,
  },
  {
    original: gallery16,
  },
  {
    original: gallery17,
  },
  {
    original: gallery18,
  },
  {
    original: gallery19,
  },
  {
    original: gallery20,
  },
  {
    original: gallery21,
  },
  {
    original: gallery22,
  },
  {
    original: gallery23,
  },
  {
    original: gallery24,
  },
  {
    original: gallery25,
  },
  {
    original: gallery26,
  },
  // {
  //   original: gallery27,
  // },
]

export const Gallery = () => {
  return (
    <ImageGallery
      items={images}
      showBullets={true}
      showThumbnails={false}
      showPlayButton={false}
      lazyLoad={true}
    />
  )
}
